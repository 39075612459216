import React from "react";
import { View, Text, Button, Image, ScrollView } from "react-native";
import { observer } from "mobx-react";
import { ListModel, ITEM_COUNT, ITEM_SIZE, ITEM_PADDING } from "./models";

const listModel = new ListModel();

export const Picture = observer((props) => {
  const { p } = props;
  const pd = ITEM_PADDING;
  const sz = ITEM_SIZE - 2 * ITEM_PADDING;
  const duration = "0.4s";

  return (
    <View
      style={{
        position: "absolute",
        transition: duration,
        ...p.rect,
      }}
    >
      <Image
        style={{
          boxShadow: "rgba(0, 0, 0, 0.74) 0px 3px 8px",
          transition: duration,
          position: "absolute",
          resizeMode: "cover",
          left: pd,
          transform: `rotate(${p.rotation}deg)`,
          top: pd,
          height: sz,
          width: sz,
        }}
        source={p.url}
      />
      {p.needFoldUnfoldButton && (
        <View
          style={{
            position: "absolute",
            left: pd,
            bottom: pd,
            width: ITEM_SIZE * 0.7,
          }}
        >
          <Button
            onPress={() => p.group.setFolded(!p.group.folded)}
            title={
              p.group.folded
                ? `Unfold ${p.group.pictures.length}`
                : `Fold ${p.group.pictures.length}`
            }
          ></Button>
        </View>
      )}
    </View>
  );
});

const App = observer(() => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <View style={{ hegith: 20, display: "flex", flexDirection: "row", justifyContent: 'flex-start' , padding: 5}}>
        <Button onPress={() => listModel.foldAll()} title="Fold All" />
        <Button onPress={() => listModel.unfoldAll()} title="Unfold All" />
      </View>
      <ScrollView
        style={{
          borderWidth: 1,
          borderColor: "silver",
          width: ITEM_SIZE * ITEM_COUNT + 10,
          height: "80%",
        }}
      >
        <View style={{ height: listModel.height }}>
          {listModel.revercedPictues.map((p) => (
            <Picture key={p.id} p={p}></Picture>
          ))}
        </View>
      </ScrollView>
    </div>
  );
});

export default App;
