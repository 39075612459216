import { makeAutoObservable, observable } from "mobx";
import { range, uniqueId, reverse } from "lodash";

export const ITEM_SIZE = 200;
export const ITEM_COUNT = 4;
export const ITEM_PADDING = 15;

export class ListModel {
  groups = observable.array();

  constructor() {
    makeAutoObservable(this);
    this.initRandom();
  }

  get height() {
    return this.pictures.length
      ? this.pictures[this.pictures.length - 1].rect.top + ITEM_SIZE
      : 100;
  }

  foldAll() {
    for (const g of this.groups) {
      g.setFolded(true);
    }
  }

  unfoldAll() {
    for (const g of this.groups) {
      g.setFolded(false);
    }
  }

  initRandom() {
    const w = (ITEM_SIZE - 2 * ITEM_PADDING) * 2;
    const h = (ITEM_SIZE - 2 * ITEM_PADDING) * 2;
    range(0, 20).forEach((v) => {
      const g = new GroupModel(this);
      this.groups.push(g);
      const count = Math.floor(Math.random() * 8);
      const fi = 15;
      range(0, count).forEach((m) => {
        let i;
        if (m == 0) {
          i = new PictureModel(
            g,
            `https://picsum.photos/id/${v + fi}/${w}/${h}`
          );
        } else {
          const grayscale = Math.random() < 0.5 ? "grayscale&" : "";
          i = new PictureModel(
            g,
            `https://picsum.photos/id/${v + fi}/${w}/${h}?${grayscale}blur=${m}`
          );
        }
        g.pictures.push(i);
      });
    });
  }

  get pictures() {
    const res = [];
    for (const g of this.groups) {
      for (const p of g.pictures) {
        res.push(p);
      }
    }
    return res;
  }

  get revercedPictues() {
    return reverse([...this.pictures]);
  }

  get items() {
    const res = [];
    for (const g of this.groups) {
      if (g.folded) {
        g.pictures[0] && res.push(g.pictures[0]);
      } else {
        for (const p of g.pictures) {
          res.push(p);
        }
      }
    }
    return res;
  }
}

class GroupModel {
  pictures = observable.array();
  folded = false;

  constructor(lm) {
    this.id = uniqueId("group");
    this.list = lm;
    makeAutoObservable(this);
  }

  setFolded(v) {
    this.folded = v;
  }
}

class PictureModel {
  constructor(group, url) {
    this.group = group;
    this.id = uniqueId("picture");
    this.url = url;
    const angle = 20;
    this._rotation = Math.round(Math.random() * 2 * angle - angle);
    makeAutoObservable(this);
  }

  get needFoldUnfoldButton() {
    return this.isFirst && this.group.pictures.length > 1;
  }

  get list() {
    return this.group.list;
  }

  get rotation() {
    return this.group.folded && this.indexInGroup !== 0 ? this._rotation : 0;
  }

  get indexInGroup() {
    return this.group.pictures.indexOf(this);
  }

  get isFirst() {
    return this.indexInGroup === 0;
  }

  get indexInList() {
    const ii = this.group.folded ? this.group.pictures[0] : this;
    return this.list.items.length ? this.list.items.indexOf(ii) : 0;
  }

  get rect() {
    const left = (this.indexInList % ITEM_COUNT) * ITEM_SIZE;
    const top = Math.floor(this.indexInList / ITEM_COUNT) * ITEM_SIZE;
    const width = ITEM_SIZE;
    const height = ITEM_SIZE;
    return { left, top, width, height };
  }
}
